<template>
  <div class="materialManage">
    <van-nav-bar
      left-arrow
      fixed
      safe-area-inset-top
      placeholder
      title="提料管理"
      @click-left="leftClick"
    />
    <!-- 实时应收 -->
    <div class="totalMoney">
      <h1>{{data.status =="3" ? (+data.money + +data.makeUpMoney - +data.moneyTiCun - +data.moneyExpress - +data.moneyInsurance - +data.warehouseMoney - +data.weight*pricehj).toFixed(2) :'--' }}</h1>
      <div>实时剩余应收</div>
    </div>
    <!-- money  细节 -->
    <div class="moneyDetails">
      <div class="weight">
        <div class="number">{{data.weight}}</div>
        <div class="k">提料克重(克)</div>
      </div>
      <div class="money">
        <div class="number">{{data.initPrice}}</div>
        <div class="k">初始金价(元/克)</div>
      </div>
      <div class="talMoney">
        <div class="number">{{data.status =="3"? pricehj:data.nowPrice}}</div>
        <div class="k">{{data.status =="3" ?'实时金价(/元克)':'结算金价'}}</div>
      </div>
    </div>
    <!-- 费用计算 -->
    <div class="costCalculation">
      <div class="icon"></div>
      <h3>费用计算</h3>
    </div>
    <!-- 计算 -->
    <div class="flex">
      <div class="leftText">累计仓租  (自{{data.time | formatDate_ | formatTimeYear}}起)</div>
      <div class="rightNumber">{{data.warehouseMoney}}</div>
    </div>
    <div class="flex">
      <div class="leftText">提纯费</div>
      <div class="rightNumber">{{data.moneyTiCun}}</div>
    </div>
    <div class="flex">
      <div class="leftText">快递费</div>
      <div class="rightNumber">{{data.moneyExpress}}</div>
    </div>
    <div class="flex">
      <div class="leftText">保险费</div>
      <div class="rightNumber">{{data.moneyInsurance}}</div>
    </div>
    <div class="flex">
      <div class="leftText">已付金额</div>
      <div class="rightNumber">{{data.money}}</div>
    </div>
    <div class="flex">
      <div class="leftText">补交押金</div>
      <div class="rightNumber">{{data.makeUpMoney}}</div>
    </div>
    <div class="flex">
      <div class="leftbtmText">剩余比例</div>
      <div class="rightbtmNumber" :style="{color:data.isOverPercent == 'true'?'red':'#60b672'}">{{ data.status =="3"? ((+data.money - +data.warehouseMoney + +data.makeUpMoney)*100 / (+data.weight*pricehj)).toFixed(2): data.currentDepositPercent}}</div>
    </div>
    <div class="bucang">
      <span class="left">如需补仓,请拔打电话</span>
      <span class="right">4008-606-806</span>
    </div>
    <div class="jiesuan">
      <div class="jieSuan" @click="balance" :style="{backgroundColor:data.status == '3'?'#ff2d2e':'#ccc'}">{{data.isOver == "true"?'已结算':data.status == "3"?'结算':'结算申请已提交' }}</div>
    </div>

    <!-- 提示框 -->
    <van-dialog v-model="show" title="提示" show-cancel-button @confirm="confirm">
      <p>您正在进行结算操作,提交结算申请后工作人员将尽快为您审核,是否确定结算?</p>
    </van-dialog>
  </div>
</template>

<script>
import *as api from'@/utils/api'
import Stomp from "stompjs";
import { getOrderMaterialData, MaterialOrderJieSuan } from "@/utils/api";
export default {
  data() {
    return {
      data:{},
      show:false,
      openId:'',
      // 是否开盘
      trade_: false,
      postTime: '',
      // 首次加载的数据
      initDatalist: [],
      firstloadData: true,
      // 所有商品数据
      allpro:[],
      pricehj:0,
      initPriceName:{
        huangjin:'huangjin9999',
      },
      client: {},
    }
  },
  watch:{
    allpro:function(){
      this.updatearr(this.initPriceName)
    }
  },
  methods:{
    // 弹窗确认按钮事件
    async  confirm(){
      await api.MaterialOrderJieSuan({openId:this.openId,time:this.postTime})
      this.$router.push({
        name:'submitMaterialExplain'
      })
    },
    // 结算按钮事件
    balance(){
      // 是预料单
      if (this.data.status == '3') {
        if (this.trade_) {
          // 开盘中
          this.show = true
          // 弹窗确认按钮事件
        }else{
          //  未开盘
          this.$toast('停盘中,无法交易')
        }
      }else{
        // 不是预料单
        return
      }
    },
    leftClick(){
      this.$router.push({
        name:'materialOrder'
      })
    },
    connect() {
      this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      // ---初始化mqtt客户端，并连接mqtt服务
      const headers = {
        login: "username",
        passcode: "password"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      this.client.debug = null;
    },
    onConnected() {
      const topic = "/price/all";
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    onFailed(frame) {
      console.log("错误: " + frame);
      if (!this.client.connected) {
        setTimeout(() => {
          this.connect();
        }, 1000);
      }
    },
    unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
    },
    responseCallback (frame) {
      this.allpro = []
      const resbody = eval('['+this.unzip(frame.body)+']')[0]
      const parse_res = JSON.parse(JSON.stringify(resbody));
      this.trade_ = resbody.trade;
      this.postTime = resbody.time;
      if(this.firstloadData){
        this.initDatalist = resbody
        this.firstloadData = false
        for(const i in this.initDatalist){
          if(this.initDatalist[i]['prices'] != undefined){

            this.allpro.push(this.initDatalist[i]['prices'])
          }
        }
      }else{
        this.initDatalist = this.deepMerge(this.initDatalist,parse_res)
        for(const i in this.initDatalist){
          if(this.initDatalist[i]['prices'] != undefined){
            this.allpro.push(this.initDatalist[i]['prices'])
          }
        }
      }
    },
    deepMerge(...objs) {
      const than = this
      const result = Object.create(null)
      objs.forEach(obj => {
        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key]
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val)
              } else {
                result[key] = than.deepMerge(val)
              }
            } else {
              //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val]
              } else {
                result[key] = val
              }
            }
          })
        }
      })
      return result
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString
      return toString.call(val) === '[object Object]'
    },
    // 价格变动更新视图
    pricenew(obj){
      this.allpro.find((x)=>{
        if(x[obj.huangjin]){
          this.pricehj = x[obj.huangjin]['xiaoshou']
        }
      })
    },
    // 更新数组
    updatearr(obj){
      this.pricenew(obj)
    },
  },
  async  mounted(){
    this.connect()
    const orderId = this.$route.query.openId
    this.openId = this.$route.query.openId
    let res = await api.getOrderMaterialData({orderId})
    this.data = res.data
    console.log('1',res);
  },
  destroyed() {
    clearTimeout(this.setInterval);
    this.client.disconnect(function() {
      // 断开连接
      console.log("See you next time!");
    });
  },
}
</script>

<style lang="scss" scoped>
::v-deep{
  .van-nav-bar{
    height: 90px;
    .van-nav-bar__content{
      line-height: 90px;
      height: 90px;
      .van-nav-bar__title{
        font-size: 36px;
      }
      .van-icon{
        font-size: 36px;
        color: #1e1e1e;
      }
    }
  }
  .van-dialog{
    width: 500px;
  }
  .van-dialog__header{
    padding-top: 0;
    line-height: 70px;
    font-size: 32px;
  }
  .van-dialog__content {
    text-align: center;
    height: 100px;
    font-size: 26px;
  }
  .van-dialog__cancel{
    height: 82px;
  }
  .van-button__content{
  }
}
.totalMoney{
  width: 100%;
  padding: 50px;
  background-color: #fff;
  h1,div{
    width: 100%;
    text-align: center;
  }
  h1{
    font-size: 42px;
    color: red;
  }
  div{
    font-size: 28px;
    color: #c1c1c1;
  }
}
.moneyDetails{
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 18px 0;
  display: flex;
  .money{
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  div{
    flex: 1;
    text-align: center;
    color: #1a1a1a;
    .number{
      font-size: 26px;
    }
    .k{
      font-size: 22px;
    }
  }
}
.costCalculation{
  padding: 18px 20px;
  display: flex;
  background-color: #f5f6f7;
  .icon{
    width: 30px;
    height:30px;
    border-radius: 50%;
    background-color: #ff2d2e;
    margin-right: 20px;
  }
  h3{
    font-size: 28px;
    color: #ccc;
  }
}
.flex{
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 80px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  .leftText{
    color: #ccc;
  }
  .leftbtmText{
    color: #1a1a1a;
  }
  .rightbtmNumber{
    color: #60b672;
  }
  div{
    line-height: 80px;
    font-size: 26px;
  }
}
.bucang{
  padding: 20px;
  background-color: #f5f6f7;
  .left{
    color: #ccc;
  }
  .right{
    margin-left: 4px;
    color: #ff2d2e;
  }
}
.jiesuan{
  width: 100%;
  padding: 0 20px;
  .jieSuan{
    width: 100%;
    color: #fff;
    height: 70px;
    font-size: 28px;
    text-align: center;
    line-height: 70px;
    border-radius: 12px;
  }
}
.materialManage{
  background-color: #f5f6f7;
  height: 100%;
}
</style>